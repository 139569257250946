import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ContactLink from './ContactLink';
import UserRelationButton from '../../relation_button';

import { appendProtocol, stripTrailingSlash, stripProtocol } from '../../../../utility/links';
import { formatNumberWithCommas, pluralize } from '../../../../utility/formatters';

import buttonStyles from '../../../../styles/global_ui/buttons.css';
import styles from './user_card.css';

const _getButtons = ({ fetchedCurrentUser, isProfileOwner, openAlert, profile, rootPath, updateResource }) => {
  if (!fetchedCurrentUser) return null;
  if (isProfileOwner) return _getOwnerButtons({ rootPath });

  return _getPublicButtons({ fetchedCurrentUser, openAlert, profile, updateResource });
};

const _getOwnerButtons = ({ rootPath }) => (
  <Fragment>
    <a className={`${buttonStyles.md} ${buttonStyles.secondary}`} href={`${stripTrailingSlash(rootPath)}/dashboard`}>View dashboard</a>
    <a className={buttonStyles.md} href={`${stripTrailingSlash(rootPath)}/dashboard/profile`}>Edit profile</a>
  </Fragment>
);

const _getPublicButtons = ({ fetchedCurrentUser, openAlert, profile, updateResource }) => (
  <Fragment>
    <UserRelationButton
      id={profile.id}
      onClick={(createOrDeleteBool) => {
        updateResource(
          null,
          'profileStats',
          ['profileStats', 'followers'],
          createOrDeleteBool,
          'user_card_follow_button',
        );
      }}
      type="followed_user_md"
    />
    <ContactLink
      className={`${buttonStyles.md} ${buttonStyles.secondary}`}
      fetchedCurrentUser={fetchedCurrentUser}
      summonDialog={openAlert}
      userId={profile.id}
    />
  </Fragment>
);

const UserCard = ({ currentUser, isProfileOwner, fetchedCurrentUser, openAlert, openDialog, profile, profileStats, rootPath, transition, updateResource }) => (
  <div className={styles.root}>
    <img className={styles.avatar} src={profile.avatar_url} />
    <div className={styles.userInfo}>
      <h1 className={styles.name}>{profile.name}</h1>
      <div className={styles.stats}>
        <a className={styles.stat} href="javascript:void(0);" onClick={() => transition('/projects')}>{`${formatNumberWithCommas(profileStats.live_projects)} ${pluralize('Project', profileStats.live_projects)}`}</a>
        <a className={styles.stat} href="javascript:void(0);" onClick={() => openDialog('followers')}>{`${formatNumberWithCommas(profileStats.followers)} ${pluralize('Follower', profileStats.followers)}`}</a>
        <a className={styles.stat} href="javascript:void(0);" onClick={() => openDialog('following')}>{`${formatNumberWithCommas(profileStats.following)} Following`}</a>
      </div>
      {profile.website
      && <a className={styles.website} href={appendProtocol(profile.website)} rel="noopener noreferrer nofollow" target="_blank">{stripProtocol(stripTrailingSlash(profile.website))}</a>}
      <div className={styles.buttons}>
        {_getButtons({ fetchedCurrentUser, isProfileOwner, openAlert, profile, rootPath, updateResource })}
      </div>
    </div>
  </div>
);

UserCard.propTypes = {
  currentUser: PropTypes.object.isRequired,
  fetchedCurrentUser: PropTypes.bool.isRequired,
  isProfileOwner: PropTypes.bool.isRequired,
  openAlert: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    available_for_hire: PropTypes.bool,
    avatar_url: PropTypes.string,
    bio: PropTypes.string,
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    email: PropTypes.string,
    hourly_rate: PropTypes.number,
    id: PropTypes.number,
    interest: PropTypes.array,
    name: PropTypes.string,
    skills: PropTypes.array,
    state: PropTypes.string,
    stats: PropTypes.shape({
      followers: PropTypes.number,
      following: PropTypes.number,
      live_projects: PropTypes.number,
      reputation: PropTypes.number,
    }),
    user_name: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
  profileStats: PropTypes.shape({ // Used for updating counters.
    followers: PropTypes.number,
    following: PropTypes.number,
    live_projects: PropTypes.number,
    reputation: PropTypes.number,
  }),
  rootPath: PropTypes.string.isRequired,
  transition: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired,
};

export default UserCard;
