import React from 'react';
import PropTypes from 'prop-types';

import styles from './link_list.css';

const Link = ({ className = styles.link, item, trackLinkClick }) => (
  <a
    className={className}
    href={item.url}
    onClick={(e) => trackLinkClick({
      delayRedirect: item.noDelay ? false : true,
      e,
      type: 'link-list',
    })}
    title={item.name}
    {...(item.props || {})}
  >
    {item.name}
  </a>
);

const LinkList = ({ className, items, trackLinkClick, viewAllLink }) => (
  <div className={className}>
    {items.map((item, i) => (
      <Link key={i} item={item} trackLinkClick={trackLinkClick} />
    ))}
    {viewAllLink
    && <Link className={styles.viewAllLink} item={viewAllLink} trackLinkClick={trackLinkClick} />}
  </div>
);

LinkList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
  trackLinkClick: PropTypes.func,
  viewAllLink: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

LinkList.defaultProps = {
  className: '',
  trackLinkClick: () => {},
  viewAllLink: null,
};

export default LinkList;
