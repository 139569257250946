import React, { useCallback, useEffect, useState } from 'react';

import SurveyPopup from '../SurveyPopup';
import { SURVEY_SLUG } from '../SurveyPopup/enums';
import { graphMutateWithoutUser } from '../../../requests/graphql';
import { getCookie } from '../../../services/cookies';
import errorHandler from '../../../services/error_handler';
import { summonGlobalMessenger } from '../../../utility/dispatchers';
import { json, VERSION } from './surveyJson';

import animation from '../../../styles/global_ui/animation';
import layout from '../../../styles/global_ui/layout';
import typography from '../../../styles/global_ui/typography';
import util from '../../../styles/global_ui/util';
import style from './activeFeedback.css';

const c = {
  body: `${style.body} ${animation.pulse}`,
  container: `${layout.flexColumn} ${layout.gutter10}`,
  questionLabel: `${typography.bodyXS} ${typography.charcoal} ${typography.textCenter}`,
  error: `${typography.error} ${typography.bodyXS} ${typography.textCenter}`,
  hidden: `${util.displayNone}`,
};

const customCss = {
  question: {
    header: c.questionLabel,
    hasError: c.error,
    titleBar: c.hidden, // hide action buttons for questions. eg. "clear", "more", "..."
  },
  completedPage: c.container,
};

const parseCookie = (name) => {
  try {
    const test = JSON.parse(decodeURIComponent(getCookie(name)));
    return test;
  } catch (error) {
    errorHandler('ActiveFeedbackSurvey parseCookie:', error);
    return {};
  }
};

const ActiveFeedbackSurvey = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeLapsed, setTimeLapsed] = useState(false);
  const cookie = parseCookie('hck_surveys');
  const surveyExpiresAt = cookie?.[SURVEY_SLUG.ACTIVE_GENERAL_FEEDBACK];
  const viewed = surveyExpiresAt === 'viewed';

  const saveSurveyResults = useCallback(({ content }) => {
    const response = {
      content: JSON.stringify(content),
      survey_slug: SURVEY_SLUG.ACTIVE_GENERAL_FEEDBACK,
      version: VERSION,
    };

    graphMutateWithoutUser({ t: 'create_survey_result' }, { result: response })
      .catch((err) => {
        summonGlobalMessenger({ msg: 'Oops, something went wrong. Try again later.', type: 'error' });
        return err;
      });
  }, []);

  const handleOpenChange = (isOpening) => {
    if (!isOpening) {
      const newCookie = encodeURIComponent(JSON.stringify({ ...cookie, [SURVEY_SLUG.ACTIVE_GENERAL_FEEDBACK]: 'viewed' }));
      const currentTime = Date.now();
      const sixMonths = 182 * 24 * 60 * 60 * 1000;
      const expires = currentTime + sixMonths;
      const date = new Date(expires).toUTCString();
      // not using setCookie due to host mismatch, and setCookie also stringifies the value, but doesn't encode. We end up with extra set of quotes.
      document.cookie = `hck_surveys=${newCookie}; path=/; expires=${date}`;
      setIsOpen(false);
    }
  };

  const handleOpen = useCallback(() => setTimeout(() => {
    // dispatch openSurvey event when opening a survey so that other surveys know not to open
    const event = new Event('openSurvey');
    document.dispatchEvent(event);
    setIsOpen(true);
  }, 15000), []);

  useEffect(() => {
    if (viewed) {
      return;
    }
    // get current time in UTC ms
    const currentTime = Date.now();
    const duration = surveyExpiresAt < currentTime ? 0 : surveyExpiresAt - currentTime;

    setTimeout(() => setTimeLapsed(true), duration);
  }, [cookie, surveyExpiresAt, viewed]);

  useEffect(() => {
    if (!timeLapsed) {
      return;
    }
    // Only open if no other survey is open.  If more than one survey is initialized, the data might get tied to the wrong survey model.
    const openSurvey = document.querySelectorAll('#surveyJS');
    if (openSurvey.length) {
      document.addEventListener('closeSurvey', handleOpen);
      return;
    }
    // Only open if sticky footer is closed to prevent overlap.
    const stickyFooter = document.getElementById('sticky-footer');
    if (stickyFooter) {
      document.addEventListener('closeStickyBanner', handleOpen);
      return;
    }

    const openTimeout = handleOpen();

    return () => {
      document.removeEventListener('closeSurvey');
      document.removeEventListener('closeStickyBanner');
      clearTimeout(openTimeout);
    };
  }, [handleOpen, timeLapsed]);

  if (viewed) {
    return null;
  }

  return (
    <SurveyPopup
      asDialog
      onSubmit={saveSurveyResults}
      popup={{
        classList: { container: c.body },
        hasClose: true,
        open: isOpen,
        title: 'A quick feedback survey.',
        onOpenChange: handleOpenChange,
      }}
      surveyCss={customCss}
      surveyJson={json}
    />
  );
};

export default ActiveFeedbackSurvey;
